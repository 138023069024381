<template>

    <div>
        <!-- 头部区域 -->
        <div class="header">
            <div class="wrapper">
                <!-- logo -->
                <div class="logo">
                    <h1><a href="">bbb</a></h1>
                </div>
            </div>
        </div>

        <div class="content">
            <div><h4> <router-link to="/">首页</router-link> > 详情 </h4></div>
            <div>
                <h2>{{ find_name }}</h2>
                <h3>更新时间 {{ update_time }} 12 浏览 资讯</h3>
            </div>
            <p>{{ find_content }}</p>
            <div v-for="(item) in imgList " :key="item.id">
                <img :src="'/server1/creationFile/' + item" class="content_img">
            </div>
        </div>


        <div class="footer">
            <div class="wrapper">
                <div class="left">
                    <a href="#"><img src="../assets/images/logo.png" alt=""></a>
                    <p>窝啰啰是个人网站</p>
                    <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2023044301号</a>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    export default {
        data () {
            return {
                findList: [],
                imgList:[],
                find_name: "",
                find_content: "",
                update_time: "",
                find_type: "",
            }
        },
    methods: {

      FindListMethod() {
            var findId = window.sessionStorage.getItem("findId")

            this.$axios({
                url: '/server1/findListById',
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                params: {
                    findId: findId,
                }
            }).then(result => {

                if (result.data.code == 1) {
                    this.findList = result.data.data

                    this.find_name = this.findList[0].find_name
                    this.find_content = this.findList[0].find_content
                    this.update_time = this.findList[0].update_time
                    this.find_type = this.findList[0].find_type

                    this.imgList = this.findList[0].find_file.split('@')

                }
            })
        },

     

    },
    created() {
      this.FindListMethod();
    }

}

</script>

<style scoped>

    body {
            background-color: #f3f5f7;
        }

        .wrapper {
            margin: 0 auto;
            width: 650px;
        }

        .header {
            height: 68x;
            background-color: #f3f5f7;
            border-bottom: 1px solid #f2eef4;
        }

        .header .wrapper {
            display: flex;
            padding-top: 10px;
        }

        .header .wrapper .logo a {
            display: block;
            margin-top: 5px;
            width:195px;
            height: 41px;
            background-image: url("../assets/images/logo.png");
            font-size: 0;
        }

        /* 搜索区域 */
        .search {
            display: flex;
            margin-left: 75px;
            margin-top: 5px;
            padding-left: 19px;
            padding-right: 12px;
            width: 225px;
            height: 40px;
            background-color: #eee;
            border-radius: 20px;
        }

        /* 用户区域 */
        .user {
            margin-left: 32px;
            margin-top: 12px;
        }


        .user img {
            margin-right: 5px;
            /* 行内块行内垂直方向对齐方式 */
            vertical-align: middle;
        }

        .user span {
            font-size: 13px;
            color: #999;
        }

        .content {
            margin: 0 auto;
            margin-top: 35px;
            width: 650px;
            padding: 15px;
            border-radius: 5px;
            background-color: #fff;
        }
        .content h2 {
            margin: 15px 15px;
            font-size: 22px;
            font-weight: 600;
            color: #333;
        }
        .content h3 {
            margin: 15px 15px;
            font-size: 14px;
            font-weight: 400;
            color: #999;
        }
        .content h4 {
            margin: 12px 15px;
            font-size: 14px;
            font-weight: 400;
            color: #999;
        }
        .content p {
            margin: 15px 15px;
            font-size: 15px;
            font-weight: 400;
            white-space: pre-line;
            color: #666;
            white-space: pre-line;
        }
        .content_img {
            width: 90%;
            background-size: cover;
        }

        /* 版权区域 */
        .footer {
            margin-top: 60px;
            padding-top: 60px;
            height: 273px;
            background-color: #fff;
        }

        .footer .wrapper {
            display: flex;
            justify-content: space-between;
        }

        .footer .wrapper img {
            background-size: cover;
        }

        .footer .left {
            width: 400px;
        }

        .footer p {
            margin-top: 15px;
            margin-bottom: 15px;
            font-size: 12px;
            line-height: 20px;
            color: #666;
        }

        .footer .download {
            display: block;
            width: 125px;
            height: 36px;
            border: 1px solid #00a4ff;
            text-align: center;
            line-height: 36px;
            font-size: 16px;
            color: #00a4ff;
        }

        .footer .right {
            display: flex;
        }

        .footer .right dl {
            margin-left: 130px;
        }

        .footer .right dt {
            margin-bottom: 12px;
            font-size: 16px;
            /* line-height: 20px; */
        }

        .footer .right a {
            font-size: 14px;
            color: #666;
            /* line-height: 20px; */
        }

</style>