<template>
  <div id="App">
    <!-- <router-link to="/"> home </router-link>
    <router-link to="/Detail"> detail </router-link> -->
    <router-view></router-view>
  </div>
</template>

<script>

// import WLLIndex from './components/WllIndex.vue'

export default {
  components: {
    // WLLIndex,
  }
}
</script>

<style>

/* 全局css */
/* .wrapper {
    margin: 0 auto;
    width: 1200px;
}

body {
    background-color: #f3f5f7;
}

.novel {
    margin-top: 25px;
} */

</style>
