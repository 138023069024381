<template>
    <div>
        <div class="wrapper">
            <img class="top" src="../assets/images/logo.png" alt="">
            <div class="content">
            <img class="left" src="../assets/images/loginImg1.png" alt="">
            <div class="right">
                <p><span style="font-family:'华文彩云';font-weight:400;font-size: 39px;">窝啰啰后台系统</span></p>
                <p>用 户 名： <input type="text" v-model="userName"></p>
                <p>用户密码： <input type="password" v-model="pwd"></p>
                <p><button @click="LoginMethod()">登录</button></p>
        </div>
    </div>
  </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                userName:"",
                pwd:"",
            }
        },
        methods: {
            LoginMethod()
            {
                
                this.$axios({
                url: '/server1/verifyUserList',
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                params:{
                    userName: this.userName,
                    pwd: this.pwd
                }
                }).then((result) => {

                console.log(result.data.msg)

                if (result.data.code == 1) {

                    console.log(result.data.data)
                    // console.log(result.data.data.user_id)

                    var json = result.data.data;
                    var jsonArr = json.split('@');
                    var token = jsonArr[0];
                    var userId = jsonArr[1];

                    localStorage.setItem("token", token)
                    localStorage.setItem('userId', userId);
                    // location.replace("/creationMange1.html");
                    
                    this.$router.push({ path: '/findmanage', query: {  } })

                } else {
                    alert(result.data.msg)// 用户名或密码错误
                }

                })

            },
        }
    }

</script>

<style scoped>
    .wrapper {
      margin: 0 auto;
      width: 900px;
    }
    .top {
      margin-top: 50px;
      margin-bottom: 20px;
    }

    .content {
      display: flex;

    }

    .left {
      width: 557px;
      height: 450px;
    }

    .right {
      width: 557px;
      height: 450px;
      text-align: center;
      padding: 50px 10px;
      background-color: skyblue;
    }

    .right p {
      margin: 20px 0px;
    }

    .right p input {
      width: 150px;
      height: 30px;
    }

    .right p button {
      width: 90px;
      height: 30px;
    }
</style>