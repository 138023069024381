import Vue from 'vue'
import App from './App.vue'
import './styles/base.css' // css 样式重置
import './assets/iconfont/iconfont.css' // 字体图标的样式
import axios from 'axios'
import VueRouter from 'vue-router'

import WLLIndex from './components/WllIndex.vue'
import WllDetail from './components/WllDetail.vue'
import Login from './components/Login.vue'
import FindManage from './components/FindManage.vue'

Vue.config.productionTip = false

// 全局变量
Vue.prototype.$axios= axios
// Vue.prototype.$urlStr = 'http://42.192.50.238:8080'

Vue.use(VueRouter)

const router1 = new VueRouter({
  routes: [
    { path:'/', component: WLLIndex },
    { path:'/detail', component: WllDetail },
    // { path:'/login', component: Login },
    // { path:'/findmanage', component: FindManage }
  ]
})

new Vue({
  render: h => h(App),
  router: router1,
}).$mount('#app')
