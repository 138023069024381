<template>
    <div>
        <div class="header1">
        <div class="top-right">
            <img src="../assets/images/logo.png" alt="">
            <span>窝啰啰后台系统</span>
        </div>
        <div class="top-left">
            <a href="#"> <img src="../assets/images/editUser.png" alt=""> 修改密码</a>
            <a href="#"> <img src="../assets/images/logout.png" alt=""> 退出登录</a>
        </div>
    </div>
    <div class="content">
        <div class="left">
            <!--            <h3>管理列表</h3>-->
            <ul>
                <li><a href="#" @click="ChangeManagerTypeMethod(0)">作品管理</a></li>
                <li><a href="#" @click="ChangeManagerTypeMethod(1)">资讯管理</a></li>
                <li><a href="#" @click="ChangeManagerTypeMethod(2)">用户管理</a></li>
            </ul>
        </div>
        <div class="right">
            <p class="right-title">资讯管理</p>
            <div class="search">
                <p>资讯名称 <input type="text" placeholder="请输入名称"></p>
                <p><a href="#" class="search-button">查询</a></p>
            </div>
            <p><a href="#" class="add-button" @click="ShowPopViewMethod">+新增资讯</a></p>
            <div class="show-table">

                <table>
                    <thead>
                    <tr>
                        <th>资讯名称</th>
                        <th>资讯文件</th>
                        <th>类型</th>
                        <th>更新时间</th>
                        <th>操作</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item) in findList " :key="item.id">

                        <td>{{ item.find_name }}</td>

                        <td class="fileList">
                            <ul>
                                <li v-for="(item) in item.find_file.split('@')" :key="item">
                                    <a target="_blank" :href="'/server1/creationFile/' + item">{{item}}</a>
                                </li>
                            </ul>
                        </td>

                        <td>{{ item.find_type }}</td>
                        <td>{{ item.update_time }}</td>
                        <td>
                            <a href="#" @click="ShowPopViewMethod2(item)">编辑</a>
                            <a href="#" @click="ShowAlertViewMethod(item)">删除</a>
                        </td>

                    </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>
    <div class="popview" v-show="popviewHidden">
        <div class="centerview">
            <p class="add-title">资讯管理</p>
            <p class="add-input">资讯名称 <input v-model.trim="find_name" type="text" placeholder="请输入作品名称"></p>
            <p class="add-input">审核状态
                <select v-model="find_status">
                    <option value=0>0</option>
                    <option value=1>1</option>
                </select>
            </p>
            <p class="add-input">类型
                <select v-model="find_type">
                    <option value="资讯类">资讯类</option>
                    <option value="动态类">动态类</option>
                </select>
            </p>
            <p class="add-input"><span>资讯文本</span> <textarea v-model="find_content" placeholder="请输入资讯文本"></textarea></p>

            <ul>
                <li v-for="(item) in imgList" :key="item">
                    <a target="_blank" :href="'/server1/creationFile/' + item">{{item}}</a>
                    <a href="#" @click="DeleteFileMethod(item)" class="deleteFile">删除</a>
                </li>
            </ul>

            <div class="add-input">
                <input id="#fileInput" type="file" name="multipartFile" value="上传文件">
                <p><a href="#" class="upload-button" @click="FileUploadMethod">上传</a></p>
                <!-- <p class="upload-url">{{ imgUrl }}</p> -->
            </div>

            <div class="add-bottom">
                <a href="#" class="save-button" @click="AddUpdateFindRequestMethod">保存</a>
                <a href="#" class="hidden-button" @click="HidePopViewMethod">取消</a>
            </div>

        </div>
    </div>

    <div class="popview" v-show="alertviewHidden">

        <div class="centerview" style="margin-top: 175px; text-align: center; height: 155px">

            <p style="font-size: 20px">确认删除？</p>

            <div class="add-bottom">
                <a href="#" class="save-button" @click="DeleteFindMethod(deleteFind)">删除</a>
                <a href="#" class="hidden-button" @click="ShowAlertViewMethod(-1)">取消</a>
            </div>

        </div>
    </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                findList: [],
                addUpdateValue:0,// 0 新增 1 修改
                modifyFindId:0,
                popviewHidden: false,
                find_name: "",
                find_type: 0,
                find_status: "",
                find_content: "",
                imgList:[],
                alertviewHidden: false,
                deleteFind: null,
                }
        },
        created() {
            this.FindListMethod()// asyn 不要柱塞下面的渲染
        },
        methods: {
            ChangeManagerTypeMethod(type) {

                // if (type == 0) {// 作品管理
                //     location.replace("/creationMange1.html");
                // }
                // if (type == 1) {// 资讯管理
                //     location.replace("/findManage.html");
                // }
                // if (type == 2) {// 设定管理
                //     location.replace("/userManage.html");
                // }
            },

            ShowPopViewMethod() {
                this.popviewHidden = true
                this.addUpdateValue = 0
            },

            HidePopViewMethod() {
                this.popviewHidden = false
            },

            ShowPopViewMethod2(findData) {

                this.popviewHidden = !this.popviewHidden
                this.addUpdateValue = 1

                // console.log("findData.find_name ->" + findData.find_name)
                // console.log("findData.creat_time ->" + findData.creat_time)

                this.find_name = findData.find_name
                this.find_type = findData.find_type
                this.find_status = findData.find_status
                this.find_content = findData.find_content;
                this.modifyFindId = findData.find_id;
                this.imgList = findData.find_file.split('@')

            },


            // 是否删除界面
            ShowAlertViewMethod(item) {
                this.alertviewHidden = !this.alertviewHidden
                this.deleteFind = item
            },

            // 请求章节列表
            async FindListMethod() {

               this.$axios({
                    url: '/server1/findList',
                    method: 'post',
                    header1s: {
                        'Content-Type': 'application/json',
                    },
                    // params:{
                    //     creation_id:this.creation_id,
                    // }
                }).then(result => {

                    console.log("findList " + result.data.msg);

                    if (result.data.code == 1)
                    {
                        this.findList = result.data.data;
                    }
                    if (result.data.msg == "nologin")
                    {
                        location.replace("/login.html");
                    }


                })
            },

            // 上传文件
            FileUploadMethod() {

                const fileInput = document.getElementById('#fileInput');
                const file = fileInput.files[0];
                if (file)
                {
                    // console.log("file.size ->" + file.size);

                    const formData = new FormData();
                    formData.append('multipartFile', file);

                    this.$axios.post('/server1/uploadFile', formData, {
                        header1s: {
                            'Content-Type': 'multipart/form-data;charset=UTF-8'
                        }
                    }).then(response => {
                        // alert('上传成功')
                        this.imgList.push(response.data)
                        console.log("this.imgList.length ->" + this.imgList.length)

                    }).catch(error => {
                        // console.error('上传失败', error);
                        alert('上传失败')
                    });
                }
                else
                {
                    alert('没有找到图片')
                }



            },

            // 删除文件
            DeleteFileMethod(fileName) {
                let params = {
                    fileName:fileName,
                }

                this.$axios({
                    url: "/server1/deleteFile",
                    method: 'post',
                    header1s: {
                        'Content-Type': 'application/json'
                    },
                    params:params
                }).then(result => {

                    if (result.data.code == 1)
                    {
                        var deleteIndex = 0
                        for (i = 0;i < this.imgList.length;i++)
                        {
                            if (this.imgList[i] == fileName)
                            {
                                deleteIndex = i
                            }
                        }
                        this.imgList.splice(deleteIndex, 1)
                    }
                })


            },

            // 添加动态
            AddUpdateFindRequestMethod() {
                var urlStr = this.addUpdateValue == 0 ? "/server1/addFind" : "/server1/updateFind"
                var find_id = this.modifyFindId
                var userId = localStorage.getItem("userId");
                var filePath = this.imgList.join('@')

                console.log("@@@filePath ->" + filePath)

                let params = {
                    find_id:find_id,
                    user_id: userId,
                    find_name:this.find_name,
                    find_content: this.find_content,
                    find_file:filePath,
                    find_type:this.find_type,
                    find_status:this.find_status,
                }


                this.$axios({
                    url: urlStr,
                    method: 'post',
                    header1s: {
                        'Content-Type': 'application/json'
                    },
                    params:params
                }).then(result => {

                    console.log(result.data.code);

                    if (result.data.code == 1)
                    {

                        this.HidePopViewMethod()
                        this.FindListMethod()

                        this.find_name = ""
                        this.find_content = ""
                        this.find_type = ""
                        this.find_status = 0
                        this.imgList = []

                    }
                })
            },

            // 删除动态
            DeleteFindMethod(deleteFindData) {

                console.log("deleteFindData ->" + deleteFindData)
                var findId = deleteFindData.find_id
                var find_file = deleteFindData.find_file
                this.ShowAlertViewMethod(null)

                this.$axios({
                    url: '/server1/deleteFind',
                    method: 'post',
                    header1s: {
                        'Content-Type': 'application/json',
                    },
                    params:{
                        find_id:findId,
                        find_file: find_file
                    }
                }).then(result => {

                    this.FindListMethod()

                    this.find_name = ""
                    this.find_content = ""
                    this.find_type = ""
                    this.find_status = 0
                    this.imgUrl = "";

                })
            },
        }
    }
</script>

<style scoped>

        .header1 {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 56px;
            background-color:rgba(70, 76, 91, 1);
        }

        .header1 div {
            width: 500px;
            height: 56px;
        }

        .header1 .top-right img {
            display: inline-block;
            vertical-align: middle;
            width: 151px;
            height: 35px;
            background-color: #fff;
        }

        .header1 .top-right span {
            display: inline-block;
            font-family:'华文彩云';
            vertical-align: middle;
            font-weight:400;
            font-size: 35px;
            color: #fff;
        }

        .header1 .top-left {
            margin-right: 20px;
            text-align: right;
        }

        .header1 .top-left a {
            padding: 0px 10px;
            display: inline-block;
            width: 109px;
            height: 56px;
            line-height: 56px;
            color: #fff;
            border:1px solid #999;
            font-size: 13px;
            /* text-align: center; */
        }

        .header1 .top-left a img {
            display: inline-block;
            vertical-align: middle;
            width: 25px;
            height: 25px;
        }

        .content {
            display: flex;
            justify-content: space-between;
        }

        .content .left {
            width: 15%;
            height: 850px;
            background-color: #f3f5f7;
        }

        .content .left li {
            margin: 0px 10px;
            border-bottom: 1px solid #ddd;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 16px;
        }

        .content .left li .active,
        .content .left li a:hover {
            background-color: #02a7f0;
            color: #fff;
        }

        .content .left h3 {
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 16px;
            font-weight: 400px;
            background-color: #d7d7d7;
        }

        .content .right {
            width: 85%;
            height: 850px;
        }

        .content .right .right-title {
            margin-top: 5px;
            margin-left: 5px;
            padding-left: 10px;
            width: 600px;
            height: 30px;
            line-height: 30px;
            border-left: 6px solid #02a7f0;
            text-align: left;
            font-size: 16px;
            color: #02a7f0;
        }

        .content .right .right-title .backCreationPage {
            color: #02a7f0;
            text-decoration: underline;
        }

        .content .right .search {
            padding: 25px 20px;
            /*display: flex;*/
            /*justify-content: space-between;*/
        }

        .content .right .search p {
            display: inline-block;
            vertical-align: middle;
            width: 305px;
            height: 50px;
            line-height: 50px;
        }

        .content .right .search p input {
            margin-left: 15px;
            width: 196px;
            height: 40px;
        }

        .content .right .search-button {
            margin-left: 20px;
            display: inline-block;
            text-align: center;
            /* vertical-align: middle; */
            width: 96px;
            height: 40px;
            line-height: 40px;
            color: #fff;
            background-color: #02a7f0;
        }

        .content .right .add-button {
            margin-left: 25px;
            display: inline-block;
            text-align: center;
            /* vertical-align: middle; */
            width: 125px;
            height: 35px;
            line-height: 35px;
            color: #fff;
            background-color: #02a7f0;
        }

        .content .right .show-table {
            padding: 25px 20px;
        }

        .content .right .show-table td .readFile {
            display: inline-block;
            width: 295px;
        }

        .content .right table,th,td {
            border: 1px solid #ddd;
        }

        .content .right th {
            padding: 15px 15px;
            text-align: center;
            width: 90px;
            /* height: 30px; */
            background-color: #eee;
        }

        /* 从1开始计算 */
        .content .right th:nth-child(1) {
            width: 200px;
        }

        .content .right th:nth-child(5) {
            width: 200px;
        }

        .content .right th:nth-child(6) {
            width: 160px;
        }

        .content .right td {
            padding: 15px 15px;
            text-align: center;
            width: 90px;
            /* height: 30px; */
        }

        .content .right .fileList {
            width: 325px;
        }

        .content .right .fileList li {
            width: 325px;
            margin-left: -130px;
        }

        .content .right .fileList ul a {
            width: 50px;
            height: 36px;
            line-height: 36px;
            color: #ff9901;
        }

        .content .right td a {
            display: inline-block;
            width: 50px;
            height: 36px;
            line-height: 36px;
            color: #ff9901;
        }

        .content .right td img {
            padding: 5px 5px;
            width: 90px;
            height: 120px;
        }

        .content .right table {
            border-collapse: collapse;
        }

        .popview {
            /* display: none; */
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-color:rgba(1, 1, 1, 0.35);
        }

        .popview .centerview {
            margin-top: 90px;
            margin-left: 30%;
            padding: 15px;
            width: 522px;
            height: 590px;
            background-color: #fff;
        }

        .popview .centerview .add-title {
            margin-top: 5px;
            margin-left: 10px;
            width: 90px;
            height: 30px;
            line-height: 30px;
            border-left: 6px solid #02a7f0;
            text-align: center;
            font-size: 16px;
            color: #02a7f0;
        }

        .popview .centerview .add-input {
            text-align: center;
            margin-top: 10px;
            margin-left: 15px;
            height: 65px;
        }

        .popview .centerview .add-input input {
            margin-left: 15px;
            width: 355px;
            height: 35px;
        }

        .popview .centerview .add-input select {
            margin-left: 15px;
            width: 355px;
            height: 35px;
        }

        .popview .centerview .add-input textarea {
            display: inline-block;
            margin-left: 15px;
            width: 355px;
            height: 70px;
            resize: none;
        }

        .popview .centerview .add-input span {
            display: inline-block;
            margin-bottom: 70px;
            vertical-align: middle;
        }

        .popview .centerview .add-bottom {
            display: flex;
            justify-content: space-between;
            margin:30px auto;
            width: 300px;
            height: 35px;
        }

        .popview .centerview .add-bottom .save-button {
            display: inline-block;
            text-align: center;
            width: 96px;
            height: 35px;
            line-height: 35px;
            color: #fff;
            background-color: #02a7f0;
        }

        .popview .centerview .add-bottom .hidden-button {
            display: inline-block;
            text-align: center;
            width: 96px;
            height: 35px;
            line-height: 35px;
            color: #fff;
            background-color: rgba(215, 215, 215, 1);
        }

        .popview .centerview .upload-button {
            display: inline-block;
            width: 300px;
            height: 30px;
            line-height: 30px;
            color: #fff;
            background-color: #02a7f0;
        }

        .popview .centerview li {
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
            margin-left: 35px;
            width: 80%;
            height: 30px;
            line-height: 30px;
            line-height: 30px;
            border-radius: 5px;
            background-color: #eee;
        }

        .popview .centerview li .deleteFile {
            color: #ff9901;
        }
</style>