

<template>
    <body>


  <!-- 头部区域 -->
  <div class="header">
    <div class="wrapper">
      <!-- logo -->
      <div class="logo">
        <h1>
          <a href="#"></a>
        </h1>
      </div>
      <!-- 搜索 -->
      <div class="search">
        <input type="text" placeholder="请输入关键词" v-model="searchStr" @keyup.enter="FindListMethod">
        <a href="#"></a>
      </div>
    </div>
  </div>

  

  <div class="content">

    <div class="find_bd">
      <ul>
        <li v-for="(item) in findList " :key="item.id">

          <a class="find_show" href="#" @click="JumpFindDetailPageMethod(item.find_id)">

            <div class="top">
              <h3>{{ item.find_name }}</h3>
              <p>{{ item.find_content }}</p>
            </div>

            <!-- <div class="find_content" v-if="item.find_file!=''">
              <img :style="{backgroundImage: `url(` + '/server1/creationFile/' + item.find_file.split('@')[0] + `)`}">
              <img v-if="item.find_file.split('@').length >= 2" :style="{backgroundImage: `url(` + '/server1/creationFile/' + item.find_file.split('@')[1] + `)`}">
              <img v-if="item.find_file.split('@').length >= 3" :style="{backgroundImage: `url(` + '/server1/creationFile/' + item.find_file.split('@')[2] + `)`}">
            </div> -->

          </a>

        </li>
      </ul>
    </div>

    

  </div>

  <div class="page">
    <a href="#" @click="NextPageContentMethod(-1)">上一页</a>
    <a href="#" @click="NextPageContentMethod(1)">下一页</a>
  </div>

  <div class="footer">
    <div class="wrapper">
      <div class="left">
        <a href="#"><img src="../assets/images/logo.png" alt=""></a>
        <p>窝啰啰是个人网站</p>
        <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2023044301号</a>
      </div>
    </div>
  </div>

    </body>
</template>

<script>
export default {
    data () {
        return {
            findList: [],
            pageValue:0,
            totalPage:0,
            searchStr:"",
        }
    },
    methods: {

      FindListMethod() {

        var pageSize = 5
        var offset = this.pageValue * pageSize
        var searchShow = '%' + this.searchStr + '%'

        this.$axios({
          url: '/server1/findListByNamePage',
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            type:"动态类",
            name: searchShow,
            offset:offset,
            pageSize:pageSize,
          }
        }).then(result => {

          if (result.data.code == 1) {
            console.log(result.data)

            var num = result.data.total / pageSize
            this.totalPage = Math.ceil(num)
            this.findList = result.data.data

          }
        })
      },

      SearchFindList() {

      },

      JumpFindDetailPageMethod(findId) {
        console.log("findId ->" + findId)

        window.sessionStorage.setItem("findId", findId)
        // location.replace("/findDetail.html");

        this.$router.push({ path: '/detail', query: {  } })

      },

      NextPageContentMethod(changeValue) {
        this.pageValue = this.pageValue + changeValue
        if (this.pageValue < 0) {
          alert("没有前面的内容了")
          this.pageValue = 0
        } else if (this.pageValue >= this.totalPage) {
          alert("已经到了最后一页")
          this.pageValue = this.totalPage
        } else {
          this.FindListMethod()
        }
      }

    },
    created() {
      this.FindListMethod();
    }

}
</script>

<style scoped>
body {
      background-color: #f3f5f7;
    }

    .wrapper {
      margin: 0 auto;
      width: 650px;
    }

    .header {
      height: 68x;
      background-color: #f3f5f7;
      border-bottom: 1px solid #f2eef4;
    }

    .header .wrapper {
      display: flex;
      padding-top: 10px;
    }

    .header .wrapper .logo a {
      display: block;
      margin-top: 5px;
      width:195px;
      height: 41px;
      background-image: url("../assets/images/logo.png");
      font-size: 0;
    }

    /* 搜索区域 */
    .search {
      display: flex;
      margin-left: 75px;
      margin-top: 5px;
      padding-left: 19px;
      padding-right: 12px;
      width: 225px;
      height: 40px;
      background-color: #eee;
      border-radius: 20px;
    }

    .search input {
      flex: 1;
      border-style: none;
      background-color: transparent;
      /* 去掉input控件的焦点框 */
      outline: none;
    }

    .search input::placeholder {
      font-size: 14px;
      color: #999;
    }

    .search a {
      align-self: center;
      width: 16px;
      height: 16px;
      background-image: url("../assets/images/search.png");
    }

    .page {
      text-align: center;
      padding: 5px 5px;
    }
    .page a {
      display: inline-block;
      font-size: 13px;
      background: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      color: #666;
      padding: 4px 9px;
    }
    .page a:hover {
      border: 1px solid #8cc540;
    }
    .page span .active {
      background: #8cc540;
      border: 1px solid #8cc540;
      color: #fff;
    }

    /* 用户区域 */
    .user {
      margin-left: 32px;
      margin-top: 12px;
    }


    .user img {
      margin-right: 5px;
      /* 行内块行内垂直方向对齐方式 */
      vertical-align: middle;
    }

    .user span {
      font-size: 13px;
      color: #999;
    }

    .content {
      margin: 0 auto;
      margin-top: 35px;
      width: 650px;
      padding: 15px;
    }
    .content h2 {
      margin: 12px 15px;
      font-size: 14px;
      font-weight: 400;
      color: #999;
    }

    /*内容部分*/
    .find_bd ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .find_bd li {
      margin-bottom: 14px;
      width: 566px;
      /*height: 375px;*/
      border-radius: 10px;
      /* background-color: pink; */
      background-color: white;
    }

    .find_bd li .find_show {
      display: block;
      /*height: 305px;*/
    }

    .find_bd li .top img {
      display: inline-block;
      margin-top: 12px;
      margin-left: 15px;
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }

    .find_bd li .top div {
      display: inline-block;
      vertical-align: top;
      /* margin-top: 13px;
      margin-left: 11px; */
    }

    .find_bd li .top .find_user h4 {
      margin-top: 10px;
      margin-left: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #333;
    }

    .find_bd li .top .find_user p {
      margin-top: 2px;
      margin-left: 10px;
      font-size: 12px;
      font-weight: 400;
      color: #999;
    }

    .find_bd li .top h3 {
      margin-top: 20px;
      margin-left: 15px;
      font-size: 19px;
      font-weight: 600;
      color: #333;
    }

    .find_bd li .top p {
      margin: 10px 15px;
      font-size: 14px;
      font-weight: 400;
      overflow: hidden;
      color: #666;
    }

    .find_bd .find_content {
      margin: 2px 5px;
      display: flex;
      justify-content: flex-start;
      /* background-color: pink; */
    }


    .find_bd .find_content img {
      margin: 2px 5px;
      width: 175px;
      height: 236px;
      background-size: cover;
    }

    .find_bd .find_bottom {
      margin: 13px 30px;
      display: flex;
      justify-content: space-between;
    }

    .find_bd .find_bottom img {
      width: 25px;
      height: 25px;
    }

    /* 版权区域 */
    .footer {
      margin-top: 60px;
      padding-top: 60px;
      height: 273px;
      background-color: #fff;
    }

    .footer .wrapper {
      display: flex;
      justify-content: space-between;
    }

    .footer .wrapper img {
      background-size: cover;
    }

    .footer .left {
      width: 400px;
    }

    .footer p {
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: 12px;
      line-height: 20px;
      color: #666;
    }

    .footer .download {
      display: block;
      width: 125px;
      height: 36px;
      border: 1px solid #00a4ff;
      text-align: center;
      line-height: 36px;
      font-size: 16px;
      color: #00a4ff;
    }

    .footer .right {
      display: flex;
    }

    .footer .right dl {
      margin-left: 130px;
    }

    .footer .right dt {
      margin-bottom: 12px;
      font-size: 16px;
      /* line-height: 20px; */
    }

    .footer .right a {
      font-size: 14px;
      color: #666;
      /* line-height: 20px; */
    }
</style>